/**************************************************
Sketchfab CSS
**************************************************/
/*
.mySketchFab {
    all: unset;
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100%;
    min-height: 100vh; 
}
*/
.mySketchFab {
    all: unset;
    position: fixed;
    top: -50px;
    left: 0;
    height: calc(100vh + 100px);
    width: 100vw;
  }