/**************************************************
Video CSS
**************************************************/
.MyVideo {
  margin: auto;
  display: grid;
  align-content: space-around;
  justify-content: space-between;
  max-width: 95vw;
  width: 800px;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  /*transform: translate(-50%, -50%);*/
}
.MyVideo .vMain {
  position: relative;
  display: block;
  max-width: 960px;
  grid-column: 1 / -1;
  background-color: black;
}
.MyVideo .vMain > div {
  padding-top: 56.25%;
}
.MyVideo .vMain > div > iframe {
  display: block;
  vertical-align: top;
  position: absolute;
  margin: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0;
}
.MyVideo .videoThumb {
  justify-self: center;
  border: 1px solid black;
  cursor: pointer;
  width: 100%;
}
