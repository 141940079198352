/**************************************************
Quiz CSS
**************************************************/
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100vw;
  display: grid;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
}
/**********************************
  Quiz Main
  **********************************/
/* structual css */
.quizHolder {
    padding: 15px;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.7);
}
.quiz ol,
.quiz li {
  all: unset;
}

.quiz {
  display: inline-grid;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  width: 100%;
  max-width: 500px;
  padding: 15px;
  border: 2px solid white;
}
.quiz * {
  box-sizing: border-box;
}
/**********************************
  Quiz Intro
  **********************************/
.quiz .intro {
  grid-column: 1;
  grid-row: 1;
}

/**********************************
  Quiz Questions
  **********************************/
/* structual css */
.quiz .questions {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz .questions img {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.quiz .questions ol {
  display: grid;
  width: 100%;
}
.quiz .questions .Q {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
}
.quiz .questions .Q div {
  padding: 15px;
  font-size: 120%;
  margin-bottom: 15px;
}
.quiz .questions .Q:not(.active) {
  pointer-events: none;
}
.quiz .questions .Q ol {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.quiz .questions .Q li {
  cursor: pointer;
  min-height: 85px;
  line-height: 27px;
  display: grid;
  align-items: center;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}
.quiz .questions .Q li:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
/* cosmetic & movement styles */
.quiz.slideLR .questions .Q:not(.answered):first-child {
  -webkit-transition: transform 0s, opacity 0.75s 0.75s;
  transition: transform 0s, opacity 0.75s 0.75s;
}
.quiz.slideLR .questions .Q.active {
  transform: translateX(0);
  opacity: 1;
}
.quiz.slideLR .questions .Q {
  -webkit-transition: transform 0.7s, opacity 0.75s;
  transition: transform 0.7s, opacity 0.75s;
  transform: translateX(90%);
  opacity: 0;
}

.quiz.slideLR .questions .Q.answered {
  transform: translateX(-90%);
}

/**********************************
  Quiz results
  **********************************/
/* structual css */
.quiz .results {
  grid-column: 1;
  grid-row: 2;
}
.quiz .results ul {
  display: grid;
}
.quiz .results ul > li {
  grid-column: 1;
  grid-row: 1;
}
.quiz .results ul > li:not(.active) {
  pointer-events: none;
}

/* cosmetic & movement styles */
.quiz.slideLR .results ul > li {
  -webkit-transition: transform 0.7s, opacity 0.75s;
  transition: transform 0.7s, opacity 0.75s;
  transform: scale(1);
}
.quiz.slideLR .results ul > li:not(.active) {
  opacity: 0;
  transform: scale(0.85);
}

/************************************/
.quiz ul,
.quiz li {
  all: unset;
}
.quiz .R button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  padding: 10px;
  font-size: 100%;
  -webkit-box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}
.quiz .R button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.quiz .results {
  display: grid;
  justify-items: center;
  align-items: center;
}
@keyframes animatedBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.quiz .qImg {
  max-width: 100vmin;
  max-height: 100vmin;
  height: 300px;
  width: 100%;
}

.quiz .qImg,
.quiz .cImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.quiz .qImg span,
.quiz .cImg span {
  display: none;
}

.quiz.priceQ .questions ol ol {
  grid-template-columns: repeat(2, 1fr);
}

.quiz.thisThat .questions ol ol {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}
.quiz.thisThat .questions ol ol > li {
  margin: auto;
  height: 150px;
  width: 150px;
}
