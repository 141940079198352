/**************************************************
App CSS
**************************************************/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Permanent+Marker&display=swap');

:root{
  --title-font: 'Permanent Marker', cursive;
  --body-font: 'Noto Sans JP', sans-serif;
  --main-color: #4C6DCE;
  --main-color-over:#7F99E3;
  --border-color: #300C7D;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 0.95em;
  font-family: var(--body-font);
}

body * {
  box-sizing: border-box;
}


