/**************************************************
Menu CSS
**************************************************/
.MainNav a,
.MainNav ul,
.MainNav li {
  all: unset;
}
.MainNav {
  position: fixed;
  padding: 15px;
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  padding-top: 50px;
  font-family: var(--title-font);
}
.MainNav .nav {
  position: absolute;
  margin: 15px;
  top: 0;
  left: 0;
  display: grid;
  background-color: white;
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  font-size: 1.2em;
}

.MainNav .nav .close {
  top: -15px;
  right: -15px;
  position: absolute;
  color: white;
  padding: 5px;
  background-color: var(--main-color);
  border-radius: 50%;
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -15px;
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  transition: transform 300ms;
}
.MainNav .nav .close:hover {
  transform: scale(1.2);
}
.MainNav .nav .navHolder ul {
  margin: 10px;
  padding: 5px;
  display: grid;
  grid-gap: 5px;
  max-width: calc(100vw - 50px);
  justify-self: stretch;
}
.MainNav .nav .navHolder ul li{
  justify-self: stretch;
}
.MainNav .nav .navHolder ul a{
  cursor: pointer;
  display: block;
  width: 100%;
  color: white;
  text-align: center;
  padding: 1px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background-color: var(--main-color);
  text-shadow: -1px -1px 0 var(--border-color), 
                1px -1px 0 var(--border-color), 
                -1px 1px 0 var(--border-color), 
                1px 1px 0 var(--border-color);
  transition: transform 300ms;
}
.MainNav .nav .navHolder ul a:hover{
  transform: scale(1.2);
}

.MainNav a.active {
  background-color: var(--border-color) !important;
  pointer-events: none;
}

.MainNav .menu {
  font-size: 1.5em;
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  padding: 10px;
  color:white;
  background-color: var(--main-color);
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  margin: 15px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;  
  transition: transform 300ms;
  cursor: pointer;
}
.MainNav .menu:hover {
  transform: scale(1.2);
}
.MainNav .menu img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/********************************
Menu Transitions / Animation
********************************/
.MainNav .nav {
  transform: translateX(-130%);
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.MainNav .menu {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.MainNav.open .nav {
  transform: translateX(0);
}
.MainNav.open .menu {
  transform: translateX(-130%);
}

/********************************
Responsive 
********************************/
@media only screen and (max-width:560px) {
  .MainNav .nav .navHolder div img{
    display:none;
  }
  .MainNav .nav .navHolder div {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width:400px) {
  .MainNav .nav .navHolder div ul{
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
}
