/**************************************************
ActivityHoler
**************************************************/
.activityBG{
    padding: 15px;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.7);
}
.activityBG .activityHolder {
    align-self: center;
    background-color: white;
    padding: 15px;
}
.activityBG .backButton{
    position: fixed;
    top:10px;
    right:10px;
    background-color: white;
    padding:10px;
    border-radius: 15px;
    text-decoration: none;
}