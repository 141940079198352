/**************************************************
Avatar Text CSS
**************************************************/
.avatarText {
  position: fixed;
  bottom: 0;
  right: 0;
}
.avatarText img {
  max-height: 90vh;
  max-width: 100vw;
  object-fit: contain;
  object-position: 100% 100%;
  height: 250px;
  width: auto;
  position: absolute;
  top: -199px;
  right: 0px;
  pointer-events: none;
}
.avatarText .textHolder {
  background: white;
  border: 1px solid gray;
  padding: 15px 0;
  margin: 10px;
  margin-bottom: 20px;
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  width: 600px;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: calc(100vw - 30px);
}
.avatarText .textHolder .title {
  position: absolute;
  font-family:  var(--title-font);
  font-size: 3.5em;
  color: var(--main-color);
  padding: 5px;
  border-radius: 5px;
  top: -49px;
  left: -20px;
  text-shadow: 
    -1px -1px 0 var(--border-color),
    1px -1px 0 var(--border-color),
    -1px 1px 0 var(--border-color),
    1px 1px 0 var(--border-color),
    -2px 3px 5px rgba(0, 0, 0, 0.75);
}
.avatarText .textHolder .copy {
  padding:15px;
  max-height: 133px;
  overflow: auto;
}
.avatarText .textHolder p{
  margin: 0px
}
.avatarText .textHolder p + p {
  margin-top: 15px;
}
.avatarText .textHolder .actionHolder {
  text-align: center;
}
.avatarText .textHolder .actionHolder a {
  border: 1px solid black;
  background: white;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
}
.avatarText .textHolder .nextPrev {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.avatarText .textHolder .nextPrev a {
  position: absolute;
  color: white;
  padding: 5px;
  background-color: var(--main-color);
  border-radius: 50%;
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -15px;
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  transition: transform 300ms;
}
.avatarText .textHolder .nextPrev a:hover {
  transform: scale(1.2);
}
.avatarText .textHolder .nextPrev .next { right: -10px; }
.avatarText .textHolder .nextPrev .prev { left: -10px;  }

.avatarText .backButton{
  position: fixed;
  top:10px;
  right:10px;
  background-color: white;
  padding:10px;
  border-radius: 15px;
  position: fixed;
}