@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Permanent+Marker&display=swap);
/**************************************************
Sketchfab CSS
**************************************************/
/*
.mySketchFab {
    all: unset;
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100%;
    min-height: 100vh; 
}
*/
.mySketchFab {
    all: unset;
    position: fixed;
    top: -50px;
    left: 0;
    height: calc(100vh + 100px);
    width: 100vw;
  }
/**************************************************
Quiz CSS
**************************************************/
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100vw;
  display: grid;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
}
/**********************************
  Quiz Main
  **********************************/
/* structual css */
.quizHolder {
    padding: 15px;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.7);
}
.quiz ol,
.quiz li {
  all: unset;
}

.quiz {
  display: inline-grid;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  width: 100%;
  max-width: 500px;
  padding: 15px;
  border: 2px solid white;
}
.quiz * {
  box-sizing: border-box;
}
/**********************************
  Quiz Intro
  **********************************/
.quiz .intro {
  grid-column: 1;
  grid-row: 1;
}

/**********************************
  Quiz Questions
  **********************************/
/* structual css */
.quiz .questions {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz .questions img {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.quiz .questions ol {
  display: grid;
  width: 100%;
}
.quiz .questions .Q {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
}
.quiz .questions .Q div {
  padding: 15px;
  font-size: 120%;
  margin-bottom: 15px;
}
.quiz .questions .Q:not(.active) {
  pointer-events: none;
}
.quiz .questions .Q ol {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.quiz .questions .Q li {
  cursor: pointer;
  min-height: 85px;
  line-height: 27px;
  display: grid;
  align-items: center;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}
.quiz .questions .Q li:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
/* cosmetic & movement styles */
.quiz.slideLR .questions .Q:not(.answered):first-child {
  transition: transform 0s, opacity 0.75s 0.75s;
}
.quiz.slideLR .questions .Q.active {
  transform: translateX(0);
  opacity: 1;
}
.quiz.slideLR .questions .Q {
  transition: transform 0.7s, opacity 0.75s;
  transform: translateX(90%);
  opacity: 0;
}

.quiz.slideLR .questions .Q.answered {
  transform: translateX(-90%);
}

/**********************************
  Quiz results
  **********************************/
/* structual css */
.quiz .results {
  grid-column: 1;
  grid-row: 2;
}
.quiz .results ul {
  display: grid;
}
.quiz .results ul > li {
  grid-column: 1;
  grid-row: 1;
}
.quiz .results ul > li:not(.active) {
  pointer-events: none;
}

/* cosmetic & movement styles */
.quiz.slideLR .results ul > li {
  transition: transform 0.7s, opacity 0.75s;
  transform: scale(1);
}
.quiz.slideLR .results ul > li:not(.active) {
  opacity: 0;
  transform: scale(0.85);
}

/************************************/
.quiz ul,
.quiz li {
  all: unset;
}
.quiz .R button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  padding: 10px;
  font-size: 100%;
  box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}
.quiz .R button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.quiz .results {
  display: grid;
  justify-items: center;
  align-items: center;
}
@-webkit-keyframes animatedBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animatedBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.quiz .qImg {
  max-width: 100vmin;
  max-height: 100vmin;
  height: 300px;
  width: 100%;
}

.quiz .qImg,
.quiz .cImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.quiz .qImg span,
.quiz .cImg span {
  display: none;
}

.quiz.priceQ .questions ol ol {
  grid-template-columns: repeat(2, 1fr);
}

.quiz.thisThat .questions ol ol {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}
.quiz.thisThat .questions ol ol > li {
  margin: auto;
  height: 150px;
  width: 150px;
}

/**************************************************
Video CSS
**************************************************/
.MyVideo {
  margin: auto;
  display: grid;
  align-content: space-around;
  justify-content: space-between;
  max-width: 95vw;
  width: 800px;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  /*transform: translate(-50%, -50%);*/
}
.MyVideo .vMain {
  position: relative;
  display: block;
  max-width: 960px;
  grid-column: 1 / -1;
  background-color: black;
}
.MyVideo .vMain > div {
  padding-top: 56.25%;
}
.MyVideo .vMain > div > iframe {
  display: block;
  vertical-align: top;
  position: absolute;
  margin: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0;
}
.MyVideo .videoThumb {
  justify-self: center;
  border: 1px solid black;
  cursor: pointer;
  width: 100%;
}

/**************************************************
ActivityHoler
**************************************************/
.activityBG{
    padding: 15px;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.7);
}
.activityBG .activityHolder {
    align-self: center;
    background-color: white;
    padding: 15px;
}
.activityBG .backButton{
    position: fixed;
    top:10px;
    right:10px;
    background-color: white;
    padding:10px;
    border-radius: 15px;
    text-decoration: none;
}
/**************************************************
Avatar Text CSS
**************************************************/
.avatarText {
  position: fixed;
  bottom: 0;
  right: 0;
}
.avatarText img {
  max-height: 90vh;
  max-width: 100vw;
  object-fit: contain;
  object-position: 100% 100%;
  height: 250px;
  width: auto;
  position: absolute;
  top: -199px;
  right: 0px;
  pointer-events: none;
}
.avatarText .textHolder {
  background: white;
  border: 1px solid gray;
  padding: 15px 0;
  margin: 10px;
  margin-bottom: 20px;
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  width: 600px;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: calc(100vw - 30px);
}
.avatarText .textHolder .title {
  position: absolute;
  font-family:  var(--title-font);
  font-size: 3.5em;
  color: var(--main-color);
  padding: 5px;
  border-radius: 5px;
  top: -49px;
  left: -20px;
  text-shadow: 
    -1px -1px 0 var(--border-color),
    1px -1px 0 var(--border-color),
    -1px 1px 0 var(--border-color),
    1px 1px 0 var(--border-color),
    -2px 3px 5px rgba(0, 0, 0, 0.75);
}
.avatarText .textHolder .copy {
  padding:15px;
  max-height: 133px;
  overflow: auto;
}
.avatarText .textHolder p{
  margin: 0px
}
.avatarText .textHolder p + p {
  margin-top: 15px;
}
.avatarText .textHolder .actionHolder {
  text-align: center;
}
.avatarText .textHolder .actionHolder a {
  border: 1px solid black;
  background: white;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
}
.avatarText .textHolder .nextPrev {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.avatarText .textHolder .nextPrev a {
  position: absolute;
  color: white;
  padding: 5px;
  background-color: var(--main-color);
  border-radius: 50%;
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -15px;
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  transition: transform 300ms;
}
.avatarText .textHolder .nextPrev a:hover {
  transform: scale(1.2);
}
.avatarText .textHolder .nextPrev .next { right: -10px; }
.avatarText .textHolder .nextPrev .prev { left: -10px;  }

.avatarText .backButton{
  position: fixed;
  top:10px;
  right:10px;
  background-color: white;
  padding:10px;
  border-radius: 15px;
  position: fixed;
}
/**************************************************
Menu CSS
**************************************************/
.MainNav a,
.MainNav ul,
.MainNav li {
  all: unset;
}
.MainNav {
  position: fixed;
  padding: 15px;
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  padding-top: 50px;
  font-family: var(--title-font);
}
.MainNav .nav {
  position: absolute;
  margin: 15px;
  top: 0;
  left: 0;
  display: grid;
  background-color: white;
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  font-size: 1.2em;
}

.MainNav .nav .close {
  top: -15px;
  right: -15px;
  position: absolute;
  color: white;
  padding: 5px;
  background-color: var(--main-color);
  border-radius: 50%;
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -15px;
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  transition: transform 300ms;
}
.MainNav .nav .close:hover {
  transform: scale(1.2);
}
.MainNav .nav .navHolder ul {
  margin: 10px;
  padding: 5px;
  display: grid;
  grid-gap: 5px;
  max-width: calc(100vw - 50px);
  justify-self: stretch;
}
.MainNav .nav .navHolder ul li{
  justify-self: stretch;
}
.MainNav .nav .navHolder ul a{
  cursor: pointer;
  display: block;
  width: 100%;
  color: white;
  text-align: center;
  padding: 1px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background-color: var(--main-color);
  text-shadow: -1px -1px 0 var(--border-color), 
                1px -1px 0 var(--border-color), 
                -1px 1px 0 var(--border-color), 
                1px 1px 0 var(--border-color);
  transition: transform 300ms;
}
.MainNav .nav .navHolder ul a:hover{
  transform: scale(1.2);
}

.MainNav a.active {
  background-color: var(--border-color) !important;
  pointer-events: none;
}

.MainNav .menu {
  font-size: 1.5em;
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  padding: 10px;
  color:white;
  background-color: var(--main-color);
  border: 1px solid var(--border-color);
  box-shadow: -2px 3px 16px -2px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  margin: 15px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;  
  transition: transform 300ms;
  cursor: pointer;
}
.MainNav .menu:hover {
  transform: scale(1.2);
}
.MainNav .menu img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/********************************
Menu Transitions / Animation
********************************/
.MainNav .nav {
  transform: translateX(-130%);
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.MainNav .menu {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.MainNav.open .nav {
  transform: translateX(0);
}
.MainNav.open .menu {
  transform: translateX(-130%);
}

/********************************
Responsive 
********************************/
@media only screen and (max-width:560px) {
  .MainNav .nav .navHolder div img{
    display:none;
  }
  .MainNav .nav .navHolder div {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width:400px) {
  .MainNav .nav .navHolder div ul{
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
}

/**************************************************
App CSS
**************************************************/

:root{
  --title-font: 'Permanent Marker', cursive;
  --body-font: 'Noto Sans JP', sans-serif;
  --main-color: #4C6DCE;
  --main-color-over:#7F99E3;
  --border-color: #300C7D;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 0.95em;
  font-family: 'Noto Sans JP', sans-serif;
  font-family: var(--body-font);
}

body * {
  box-sizing: border-box;
}



